import type {
    ClientInput,
    ClientPartialInput,
    CreateClientPayload,
    DeleteClientPayload,
    UpdateClientPayload,
} from '~/generated/types';
import { operationFragment } from '~/graphql/fragments/operation';
import { clientFragment } from '~/graphql/fragments/client';

export type CreateClientVariables = {
    data: ClientInput;
}

export type CreateClientData = {
    createClient: CreateClientPayload
}

export const CREATE_CLIENT_MUTATION = gql`
    mutation CreateClient($data: ClientInput!) {
        createClient(data: $data) {
            ...clientFragment
            ...operationFragment
        }
    }

    ${clientFragment}
    ${operationFragment}
`;

export type UpdateClientVariables = {
    data: ClientPartialInput;
}

export type UpdateClientData = {
    updateClient: UpdateClientPayload
}

export const UPDATE_CLIENT_MUTATION = gql`
    mutation UpdateClient($data: ClientPartialInput!) {
        updateClient(data: $data) {
            ...clientFragment
            ...operationFragment
        }
    }

    ${clientFragment}
    ${operationFragment}
`;

export type DeleteClientVariables = {
    id: string;
}

export type DeleteClientData = {
    deleteClient: DeleteClientPayload;
}

export const DELETE_CLIENT_MUTATION = gql`
    mutation DeleteClient($id: GlobalID!) {
        deleteClient(data: {id: $id}) {
            ...operationFragment
        }
    }

    ${operationFragment}
`;
